/**
 * Singleton for common model funtions.
 */

import React from 'react';
import Config from './Config';
import firebase from './firebase';
import moment from 'moment';
import reactStringReplace from "react-string-replace";

class Model {
  constructor() {
    this.type = '';
  }

  CLOUD = Config.CLOUD;
  PRIVATE_BUCKET = Config.PRIVATE_BUCKET;
  ENABLE_LIKE = false;

  collection = (name) => {
    if (name === 'users' || name === 'streamers' || name === 'orders' || name === 'sellers') {
      return firebase.firestore().collection(name);
    } else {
      return firebase.firestore().collection(`${this.type}${name}`);
    }
  };

  listData = (data) => data.docs.map((doc) => Object.assign({ id: doc.id }, doc.data()));

  async get(collection, ids) {
    const promises = ids.map((id) =>
      this.collection(collection).doc(id).get().then(
        (doc) => doc.exists ? Object.assign({ id }, doc.data()) : null));
    return await Promise.all(promises).then((data) => data.filter((x) => x));
  }

  async fetch(path, method = 'GET', body = {}) {
    if (firebase.auth().currentUser) {
      const token = await firebase.auth().currentUser.getIdToken(true);
      const data = {
        method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      };
      if (method !== 'GET' && method !== 'HEAD') {
        data.body = JSON.stringify(body);
      }
      return await fetch(`${this.CLOUD}${path}`, data).then(this.checkStatus);
    }
  }

  orderStatus = (status) => {
    switch (status) {
      case 'created':
        return '未支払い';
      case 'paid':
        return <span className="error">未発送</span>;
      case 'fullfilled':
        return <span className="green">発送済み</span>;
      case 'canceled':
        return <span className="error">キャンセル</span>;
      case 'returned':
        return <span className="error">返品申請</span>;
      case 'returnSuccess':
        return <span className="green">返品完了</span>;
      case 'returnDenied':
        return <span className="error">返品拒否</span>;
      default:
        return '状況不明';
    }
  };

  deliveryFee = (price) => (price < 5324 && price > 0) ? 324 : 0;

  checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      let error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  };

  getLiveUnitIds = (liveIds, sessions) => {
    const lastSessionUnitIds = (
      sessions
        .filter((session) => session.units)
        .reduce((previous, current) => {
          previous[current.liveId] = current.units.map((unit) => unit.code);
          return previous;
        }, {})
    );
    const mainLiveUnitIds = (
      Object
        .keys(lastSessionUnitIds)
        .filter((id) => liveIds.includes(id))
        .reduce((previous, current) => [
          ...previous,
          ...lastSessionUnitIds[current]
        ], [])
        .reduce((previous, current) => {
          if (!previous.includes(current)) {
            previous.push(current);
          }
          return previous;
        }, [])
    );
    const otherLiveUnitIds = (
      Object
        .keys(lastSessionUnitIds)
        .filter((id) => !liveIds.includes(id))
        .reduce((previous, current) => [
          ...previous,
          ...lastSessionUnitIds[current]
        ], [])
        .reduce((previous, current) => {
          if (!previous.includes(current) && !mainLiveUnitIds.includes(current)) {
            previous.push(current);
          }
          return previous;
        }, [])
    );
    return {
      mainLiveUnitIds,
      otherLiveUnitIds,
    };
  };

  getLastView = (order, mainLiveUnitIds = {}, otherLiveUnitIds = {}) => {
    let viewData = null;
    try {
      viewData = JSON.parse(order.viewData);
    } catch {
      return null;
    }
    const orderTime = moment(order.created.toDate());
    const checkPriority = (a, b) => {
      if (a.type === 'live') {
        return ['live'].includes(b.type);
      }
      if (a.type === 'archive') {
        return ['live', 'archive'].includes(b.type);
      }
      if (a.type === 'preview') {
        return ['preview', 'live', 'archive'].includes(b.type);
      }
    };
    let lastView = null;
    Object.keys(viewData).forEach((liveId) => {
      const view = { liveId, ...viewData[liveId] };
      if ((view.live && orderTime.diff(moment(view.live), 'days') <= 30)) {
        // Give priority to live.
        view.time = view.live;
        view.type = 'live';
      }
      if (!['preview', 'live', 'archive'].includes(view.type)) {
        return;
      }
      const viewTime = moment(view.time);
      if ((orderTime.diff(viewTime, 'days') <= 30)) {
        order.units.forEach((unit) => {
          view.includesMainUnit = view.includesMainUnit || mainLiveUnitIds.includes(unit.unitId);
          view.includesOtherUnit = view.includesOtherUnit || otherLiveUnitIds.includes(unit.unitId);
        });
        if (!lastView) {
          lastView = view;
          return;
        }
        if (!lastView.includesMainUnit && view.includesMainUnit && checkPriority(lastView, view)) {
          lastView = view;
          return;
        }
        if (lastView.includesMainUnit === view.includesMainUnit && lastView.time < view.time && checkPriority(lastView, view)) {
          lastView = view;
          return;
        }
        if (!lastView.otherLiveUnitIds && view.otherLiveUnitIds && checkPriority(lastView, view)) {
          lastView = view;
          return;
        }
        if (lastView.otherLiveUnitIds === view.otherLiveUnitIds && lastView.time < view.time && checkPriority(lastView, view)) {
          lastView = view;
          return;
        }
      }
    });
    return lastView;
  };

  includesUnit = (liveId, code, sessions) => {
    if (!sessions) {
      return false;
    }
    const session = sessions.findLast((session) => session.liveId === liveId);
    if (!session) {
      return false;
    }
    return session.units.findIndex((unit) => (unit.code === code)) !== -1;
  };

  getOrderData = (order, view, sessions) => {
    const liveId = view.liveId;
    const orderTime = moment(order.created.toDate());
    const price = order.units.reduce((sum, unit) =>  sum + unit.price, 0);
    const quantity = order.units.reduce((sum, unit) => { return sum + unit.quantity }, 0);
    let livePrice = 0;
    let liveQuantity = 0;
    const unitStat = [];
    order.units.forEach((unit) => {
      if (this.includesUnit(liveId, unit.unitId, sessions)) {
        livePrice += unit.price;
        liveQuantity += unit.quantity;
        unitStat.push({
          unitId: unit.unitId,
          quantity: unit.quantity,
          price: unit.price,
          type: view.type
        });
      }
    });
    return {
      uid: order.uid,
      orderId: order.orderId,
      created: orderTime.utcOffset('+0900').format(),
      day: orderTime.utcOffset('+0900').format('MMDD'),
      liveId,
      price,
      quantity,
      livePrice,
      liveQuantity,
      type: view.type,
      units: JSON.stringify(order.units),
      unitStat
    };
  };

  replaceLink(text) {
    return reactStringReplace(text, /(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)/g, (href) =>
      (<a className="live-description-link" href={href} target="_blank" rel="noopener noreferrer">{href}</a>));
  }

  shouldUseNewCookies = (orderTime) => orderTime.isAfter(moment('2020-12-31').endOf('day'));
}

const model = new Model();

export default model;
